import { examClick, judgeClick, typingClick } from './api/users'

// 统计点击
// platform: 入口来源， 1：顶部 2：个人中心
export const clickStatic = (type, params) => {
  if (
    !['judge', 'typing', 'exam'].includes(type) ||
    ['1', '2'].includes(params['platform'])
  ) {
    console.error('clickStatic: type or params error')
  }
  switch (type) {
    case 'exam':
      return examClick(params)
    case 'judge':
      return judgeClick(params)
    case 'typing':
      return typingClick(params)
    default:
      return () => {
        console.error('clickStatic: type error')
      }
  }
}
