import instance from '../request'

// 客观题入口点击
export const examClick = data => {
  return instance.post('/api/v1/users/exam_click/', data)
}

// 编程题入口点击
export const judgeClick = data => {
  return instance.post('/api/v1/users/judge_click/', data)
}

// 打字速练平台入口点击
export const typingClick = data => {
  return instance.post('/api/v1/users/typing_click/', data)
}
